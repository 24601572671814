<template>
  <div>
    <h2 class="font-22-700 dark-text mb-5 text-center">Contact Information</h2>

    <text-input
      label="Company LTD *"
      v-model="fields.name"
      :hasError="errors.name"
      :errMsg="errors.name"
    />

    <text-input
      label="Principle Contact Name or Сompany Owner"
      v-model="fields.principleContact"
    />

    <text-input
      label="Email Address *"
      v-model="fields.email"
      :hasError="errors.email"
      :errMsg="errors.email"
      :disabled="true"
    />

    <text-input label="Company Address Line 1" v-model="fields.address" />

    <text-input label="Company Address Line 2" v-model="fields.address2" />

    <div class="flex-2-cols company-address">
      <text-input
        label="City *"
        v-model="fields.city"
        :hasError="errors.city"
        :errMsg="errors.city"
        :classes="'col-lg-6 col-sm-12 company-city'"
      />

      <div class="address-wrapper col-lg-6 col-sm-12">
        <div
          :class="{
            'input-group-alternative': true,
            'input-error': errors.state,
            'pr-1': true,
            'col-sm-7': true,
          }"
        >
          <FormDropdown
            :title="'State*'"
            :isInValid="errors.state"
            :selected="fields.state"
            @update-value="handleStateChange"
            :items="stateOptions"
            :optionLabel="'name'"
            :optionValue="'abbreviation'"
            :errMsg="errors.state"
          >
          </FormDropdown>
        </div>

        <text-input
          label="Zip Code *"
          v-model="fields.zip"
          :hasError="errors.zip"
          :errMsg="errors.zip"
        />
      </div>
    </div>

    <phone-input
      inputClasses="phone-input"
      placeholder="Phone number *"
      :border-radius="14"
      :error="errors.phoneNumber"
      :value="fields.phoneNumber"
      @on-change="fields.phoneNumber = $event"
    />

    <text-input
      label="Website"
      v-model="fields.website"
      :hasError="errors.website"
      :errMsg="errors.website"
    />
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import {
  validateEmail,
  validateUrl,
  validateUsPhoneNumber,
  validateZipCode,
  deNullifyErrors,
} from "@/utils/validations"
import options from "@/utils/options"
import FormDropdown from "../../FormDropdown.vue"

export default {
  name: "company-profile-contact-information",
  components: { FormDropdown },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      stateOptions: options.stateOptions,
    }
  },
  mounted() {
    EventBus.$on("validate-company-contact-info", () => {
      this.validateContactInfo()
    })
  },
  computed: {
    isInvalidSelect() {
      return this.fields.state === ""
    },
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-contact-info")
  },
  methods: {
    validateContactInfo() {
      let errors = {
        email: !this.fields.email
          ? "Please enter your email address"
          : !validateEmail(this.fields.email)
          ? "Please enter a valid email address"
          : null,
        phoneNumber: !validateUsPhoneNumber(this.fields.phoneNumber)
          ? "Please enter a valid phone number"
          : null,
        website:
          this.fields.website && !validateUrl(this.fields.website)
            ? "Please enter a valid web address"
            : null,
        name: !this.fields.name ? "Please enter your company name" : null,
        state: !this.fields.state ? "This is required" : null,
        city: !this.fields.city ? "This is required" : null,
        zip: !this.fields.zip
          ? "Please enter your company ZIP code"
          : !validateZipCode(this.fields.zip)
          ? "Please enter a valid ZIP code"
          : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
    handleStateChange(newValue) {
      this.fields.state = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.input-error {
  .state-select.invalid-select {
    color: #e24646 !important;
  }

  .v-text-field__details {
    display: block !important;
  }
}

.state-select {
  box-shadow: none;
  background: #ffffff;
  border: 1px solid var(--Gray-300, #b0b9c9);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  line-height: 50px;
  padding: 0 1rem !important;
  color: #003049;
  font-weight: 500;
  max-width: 150px;

  &:hover {
    border: 2px solid var(--Gray-500, #677690) !important;
  }

  &:focus {
    border-color: #0095e2 !important;
    outline: unset !important;
  }

  option {
    color: #003049;
  }

  &.invalid-select,
  option [disabled] {
    color: #adb5bd;
  }
}
</style>
