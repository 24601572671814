<template>
  <profile-create-form
    :step="step"
    :totalSteps="totalSteps"
    :profileType="this.fields.jobSeeker ? 'jobseeker' : 'provider'"
    :loading="loading"
    @validate="validate"
    @handle-step-change="handleStepChange"
  >
    <!-- Step 1 -->
    <div v-show="step === 0">
      <h2 class="form-heading">Basic Information</h2>

      <p class="info-warning-text">
        Please answer all questions truthfully — businesses will question your
        expertise in interviews and your profile photo, contact info, and past
        employers are kept private until you apply for a job.
      </p>
      <p class="required-indication">* - indicates required field</p>

      <CandidateProfileEditBasicInformation
        :fields="fields"
        @proceed-next="handleNextStep"
      />
    </div>

    <!-- Step 2 -->
    <div v-show="step === 1">
      <h2 class="form-heading">Experience & Certifications</h2>

      <p class="info-warning-text">
        Please answer all questions truthfully — businesses will question your
        expertise in interviews and your profile photo, contact info, and past
        employers are kept private until you apply for a job.
      </p>
      <p class="required-indication">* - indicates required field</p>

      <CandidateProfileEditBusinessInformation
        :fields="fields"
        @proceed-next="handleNextStep"
      />
    </div>

    <!-- Step 3 -->
    <div v-show="step === 2">
      <h2 class="form-heading">Work History</h2>

      <p class="info-warning-text">
        Please answer all questions truthfully — businesses will question your
        expertise in interviews and your profile photo, contact info, and past
        employers are kept private until you apply for a job.
      </p>

      <CandidateProfileEditAdditionalInformation
        :fields="fields"
        :editMode="editMode"
        @change-photo-uploads="handlePhotoChange"
        @proceed-next="handleNextStep"
      />
    </div>
  </profile-create-form>
</template>

<script>
import formatFileNamesMixin from "@/mixins/formatFileNamesMixin"
import messagesMixin from "@/mixins/messagesMixin"
import options from "@/utils/options"
import { sleep } from "@/utils/sleep"
import { normalize } from "@/utils/validations"
import { uploadProfilePhotos } from "../uploadProfilePhotos"
import ProfileCreateForm from "../ProfileCreateForm.vue"
import { ERROR_MESSAGES } from "../../../utils/constants"
import { API_RESPONSE_ERRORS } from "../../../utils/constants"
import file from "@/api/file.js"
import { EventBus } from "../../../utils/event-bus"
import CandidateProfileEditBasicInformation from "./CandidateProfileEditBasicInformation.vue"
import CandidateProfileEditBusinessInformation from "./CandidateProfileEditBusinessInformation.vue"
import CandidateProfileEditAdditionalInformation from "./CandidateProfileEditAdditionalInformation.vue"

export default {
  mixins: [formatFileNamesMixin, messagesMixin],
  components: {
    ProfileCreateForm,
    CandidateProfileEditBasicInformation,
    CandidateProfileEditBusinessInformation,
    CandidateProfileEditAdditionalInformation,
  },
  props: {
    profile: Object,
  },
  mounted() {
    if (this.editMode) {
      this.totalSteps = 2
      this.step = 0
    }
  },
  computed: {
    maxDob() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      // Let's say you need to be at least 15 to be a Job Seeker
      maxDate.setFullYear(maxDate.getFullYear() - 15)
      return maxDate
    },
  },
  data() {
    const storedFields = JSON.parse(localStorage.getItem("profileFields"))
    const fields =
      this.profile ??
      (storedFields?.email === this.$store.state.user?.email
        ? storedFields
        : {})
    const {
      jobSeeker,
      name = "",
      lastName = "",
      email,
      zip = "",
      description = "",
      profilePhoto,
      phoneNumber = "",
      state = "",
      city = "",
    } = fields
    const {
      dateOfBirth,
      howDidYouHear = "",
      latestEducation = "",
      isAdult = "",
      authorizedInUS = "",
      requireSponsorship = "",
      validDriversLicense,
      reliableTransportation,
      positionSeeking,
      hasManagementExperience,
      managementExperienceCategories = [],
      hasCcsCard,
      oshaCertification,
      hasHandsOnExperience,
      handsOnExperiencesTrainings,
      handsOnExperiences = [],
      handsOnExperienceDescription = "",
      validCdlLicense,
      cdlClass,
      isVeteran,
      veteranStatusOptions,
      previousCompaniesWorkedFor = [],
      hasReferences,
      idealJobDescription = "",
      references = [],
      resume,
    } = jobSeeker ?? {}
    const step = 0
    return {
      deleteResume: false,
      step,
      loading: false,
      editMode: !!this.profile,
      errors: {},
      totalSteps:
        this.profile && Object.keys(this.profile).length === 0
          ? 0
          : this.profile
          ? 0
          : 2,
      trueFalse: options.trueFalse,
      categoryOptions: options.categoryOptions,
      managementExperienceOptions: options.managementExperienceOptions,
      cdlClassOptions: options.cdlClassOptions,
      howMuchExperienceOptions: options.howMuchExperienceOptions,
      latestEducationOptions: options.latestEducationOptions,
      isAdult: options.trueFalse,
      oshaCertificationOptions: options.oshaCertificationOptions,
      positionOptions: options.positionOptions,
      ratingOptions: options.ratingOptions,
      veteranStatusOptions: options.veteranStatusOptions,
      handsOnExperienceCategories: handsOnExperiences.map(
        (experience) => experience.category
      ),
      profilePhotoUpload: null,
      resumeUpload: null,
      profilePhoto,
      resume,
      fields: {
        name,
        lastName,
        email: email ?? this.$store.state.user?.email ?? "",
        zip,
        state,
        city,
        phoneNumber,
        description,
        howDidYouHear,
        profilePhoto,
        jobSeeker: {
          dateOfBirth,
          latestEducation,
          isAdult,
          authorizedInUS,
          requireSponsorship,
          validDriversLicense,
          reliableTransportation,
          positionSeeking,
          hasManagementExperience,
          managementExperienceCategories,
          hasCcsCard,
          oshaCertification,
          hasHandsOnExperience,
          handsOnExperiences,
          handsOnExperiencesTrainings,
          handsOnExperienceDescription,
          validCdlLicense,
          cdlClass,
          isVeteran,
          veteranStatusOptions,
          previousCompaniesWorkedFor: previousCompaniesWorkedFor.map(
            (company) => ({
              ...company,
            })
          ),
          hasReferences,
          idealJobDescription,
          references: references.map((reference) => ({
            ...reference,
            phone: reference.phone ?? "",
          })),
          resume,
        },
      },
    }
  },
  methods: {
    handleStepChange(step) {
      this.step = step
    },
    handlePhotoChange(profilePhotoUpload, resumeUpload, profilePhoto, resume) {
      this.profilePhotoUpload = profilePhotoUpload
      this.resumeUpload = resumeUpload
      this.profilePhoto = profilePhoto
      this.resume = resume
    },
    handleNextStep(proceedNext) {
      const step = this.step
      const data = normalize(this.fields)
      const { jobSeeker } = data
      const { hasManagementExperience, hasHandsOnExperience } = jobSeeker ?? {}

      if (!hasManagementExperience) {
        data.jobSeeker.managementExperienceCategories = []
      }

      if (hasHandsOnExperience) {
        data.jobSeeker.handsOnExperiencesTrainings = []
      }

      if (!hasHandsOnExperience) {
        data.jobSeeker.handsOnExperienceCategories = []
        data.jobSeeker.handsOnExperiences = []
      }

      if (proceedNext) {
        localStorage.setItem("profileFields", JSON.stringify(data))
        if (this.step === this.totalSteps) {
          void this.submitProfile(data)
        } else {
          localStorage.setItem("profileStep", step + 1)

          if (this.editMode) {
            document.getElementById("dashboard-profile-modal").scrollTo(0, 0)
          }

          this.step++
        }
      }
    },
    async validate() {
      const step = this.step

      if (step === 0) {
        EventBus.$emit("validate-candidate-basic-info")
      } else if (step === 1) {
        EventBus.$emit("validate-candidate-business-info")
      } else if (step === 2) {
        EventBus.$emit("validate-candidate-additional-info")
      } else {
        this.errors = {}
        this.handleNextStep(true)
      }
    },
    async submitProfile(data) {
      this.loading = true
      const { userId } = this.$store.state.user ?? {}
      try {
        const { profilePhoto: uploadedProfilePhoto } =
          await uploadProfilePhotos(this.profilePhotoUpload, [], this)
        const profilePhoto = uploadedProfilePhoto ?? this.profilePhoto

        const resumeFile = this.resumeUpload
        if (this.editMode && this.deleteResume) {
          await file
            .removeFile({
              id: this.resume,
              type: "resume",
            })
            .then(() => {
              this.resume = null
            })
        }
        const { data: resumeData } = resumeFile
          ? await file.uploadFile(resumeFile)
          : {}

        const resume = resumeData?._id ?? this.resume

        const { previousCompaniesWorkedFor: companies, hasReferences } =
          data.jobSeeker ?? {}
        const previousCompaniesWorkedFor =
          companies?.map((company) => {
            const { to, current } = company
            return {
              ...company,
              to: current ? "" : to,
            }
          }) ?? []

        const references = hasReferences ? data.jobSeeker?.references : []

        if (this.profile?.profilePhoto && !this.profilePhoto) {
          await file.removeFile({
            id: this.profile.profilePhoto.id,
            type: "profilePhoto",
          })
        }

        const profileData = {
          ...data,
          user: userId,
          profilePhoto,
          jobSeeker: {
            ...data.jobSeeker,
            previousCompaniesWorkedFor,
            references,
            resume,
          },
        }

        if (!profilePhoto) {
          delete profileData.profilePhoto
        }
        if (!resume) {
          console.log("no resume")
          delete profileData.jobSeeker.resume
        }

        const editMode = this.editMode

        const payload = editMode
          ? { profileId: this.profile._id, data: profileData }
          : profileData

        const response = await this.$store.dispatch(
          `api/${editMode ? "updateProfile" : "createProfile"}`,
          payload
        )
        if (!response.data) {
          this.showErrorMessage(
            response?.data?.errors?.[0],
            `We couldn't ${editMode ? "update" : "create"} your profile.`
          )
        } else if (response.data?._id) {
          if (response.data.errors) {
            this.showErrorMessage(
              response?.data?.errors?.[0],
              `Profile ${
                editMode ? "updated" : "created"
              }, but with errors. Please check your profile to make sure it is complete.`
            )
            sleep(3000)
          }
          this.$store.commit("UPDATE_USER_PROFILE", response.data)
          localStorage.removeItem("profileFields")
          localStorage.removeItem("profileStep")
          await this.$store.dispatch("auth/refreshToken")
          if (editMode) {
            this.$emit("close-modal")
            this.$emit("update-complete")
          } else {
            this.$router.push("/dashboard")
          }
        } else {
          this.loading = false
          // Default case
          this.showErrorMessage(
            response?.data?.errors?.[0],
            `We couldn't ${editMode ? "update" : "create"} your profile.`
          )
        }
      } catch (error) {
        this.loading = false
        if (error?.errors?.[0] === API_RESPONSE_ERRORS.INVALID_ZIP_CODE_ERROR) {
          this.showErrorMessage(
            error?.errors?.[0],
            ERROR_MESSAGES.INVALID_ZIP_CODE_INPUT
          )
        } else {
          this.showErrorMessage(
            error,
            error.message ??
              `We couldn't ${this.editMode ? "update" : "create"} your profile.`
          )
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" global>
.form-group {
  legend {
    color: var(--Gray-900, #343946);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  &.pill-error {
    legend {
      color: #e24646;
    }
  }
}
</style>

<style scoped lang="scss">
.dotted {
  border: 2px dashed #f77f00;
  color: #f77f00;
  display: inline;
  background-color: transparent;
}

.photo-container {
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 200px;
  height: 200px;
}

.photo {
  border-radius: 22px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & img {
    border-radius: 22px;
    object-fit: cover;
  }
}
.daily-responsibilities-textarea {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ffffff;
  color: #7b809d;
  border: 2px solid rgba(0, 48, 73, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 14px;
  /* height: 50px; */
  line-height: 50px;
  padding: 0 1rem;
  color: #003049;
  font-weight: 500;
}

h3 {
  font-size: 1.0625em;
  font-weight: 600;
}

.avatar-remove-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 30px;
  height: 30px;
}

.old-resume-preview {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .btn-medium {
    padding: 5px 15px;
    font-size: 15px;
  }
}

.required-indication {
  font-weight: bold;
  font-style: italic;
}
</style>
