<template>
  <profile-create-form
    :step="step"
    :totalSteps="totalSteps"
    :loading="loading"
    @validate="validate"
    @handle-step-change="handleStepChange"
  >
    <!-- Step 1 -->
    <div v-show="step === 0">
      <CompanyProfileContactInformation
        :fields="fields"
        @proceed-next="handleNextStep"
      />
    </div>

    <!-- Step 2 -->
    <div v-show="step === 1">
      <CompanyProfileEditOverview :fields="fields" />
    </div>

    <!-- Step 3 -->
    <div v-show="step === 2">
      <CompanyProfileEditBusinessServices
        :fields="fields"
        @proceed-next="handleNextStep"
      />
    </div>

    <!-- Step 4 -->
    <div v-show="step === 3">
      <CompanyProfileEditAdditional
        :fields="fields"
        @proceed-next="handleNextStep"
      />
    </div>

    <!-- Step 5 -->
    <div v-show="step === 4">
      <CompanyProfileEditPhotos
        :fields="fields"
        @change-photo-uploads="handlePhotoChange"
        @proceed-next="handleNextStep"
      />
    </div>
  </profile-create-form>
</template>

<script>
import options from "@/utils/options"
import { sleep } from "@/utils/sleep"
import messagesMixin from "@/mixins/messagesMixin"
import { normalize } from "@/utils/validations"
import ProfileCreateForm from "../ProfileCreateForm.vue"
import { uploadProfilePhotos } from "../uploadProfilePhotos"
import { ERROR_MESSAGES } from "../../../utils/constants"
import { API_RESPONSE_ERRORS } from "../../../utils/constants"
import file from "@/api/file.js"
import CompanyProfileContactInformation from "./CompanyProfileContactInformation.vue"
import { EventBus } from "../../../utils/event-bus"
import CompanyProfileEditOverview from "./CompanyProfileEditOverview.vue"
import CompanyProfileEditBusinessServices from "./CompanyProfileEditBusinessServices.vue"
import CompanyProfileEditPhotos from "./CompanyProfileEditPhotos.vue"
import CompanyProfileEditAdditional from "./CompanyProfileEditAdditional.vue"

export default {
  components: {
    ProfileCreateForm,
    CompanyProfileContactInformation,
    CompanyProfileEditOverview,
    CompanyProfileEditBusinessServices,
    CompanyProfileEditPhotos,
    CompanyProfileEditAdditional,
  },
  mixins: [messagesMixin],
  props: {
    profile: Object,
  },
  data() {
    const storedFields = JSON.parse(localStorage.getItem("profileFields"))
    const fields =
      this.profile ??
      (storedFields?.email === this.$store.state.user?.email
        ? storedFields
        : {})
    const step = 0
    const storedProfileType =
      this.$router.currentRoute.query.profileType ||
      localStorage.getItem("profileType")
    const {
      name = "",
      email,
      address = "",
      address2 = "",
      state = "",
      city = "",
      zip = "",
      workRadius = "",
      industry = [],
      selfPerformWorkCategories = [],
      tradesRelatedToProduct = [],
      providesInstallLaborServices,
      qualifiedDesignations = [],
      carryWorkersCompGeneralLiableMin,
      taxId = "",
      description = "",
      profilePhoto,
      showcasePhotos = [],
      oshaCertification,
      emr,
      apprenticeship,
      website = "",
      phoneNumber = "",
      principleContact = "",
      volumeCompletedProjects = "",
      numberOfEmployees = "",
      affiliations = [],
    } = fields ?? {}

    const {
      isContractor,
      isVendor,
      workForOtherContractors,
      licensed,
      licenseNumber,
      utilizeUnionLabor,
      paymentProcessTime = "",
    } = fields?.provider ?? {}

    return {
      step: this.profile ? 0 : storedProfileType ? step : Infinity,
      profileType: storedProfileType,
      loading: false,
      editMode: !!this.profile,
      totalSteps: 4,
      trueFalse: options.trueFalse,
      yesNo: options.yesNo,
      industryOptions: options.industryOptions,
      errors: {},
      profilePhoto,
      showcasePhotos,
      profilePhotoUpload: {},
      showcasePhotosUpload: [],
      fields: {
        name,
        email: email ?? this.$store.state.user?.email ?? "",
        address,
        address2,
        state,
        city,
        zip,
        workRadius,
        industry,
        selfPerformWorkCategories,
        tradesRelatedToProduct,
        providesInstallLaborServices,
        qualifiedDesignations,
        carryWorkersCompGeneralLiableMin,
        taxId,
        description,
        apprenticeship,
        website,
        phoneNumber,
        principleContact,
        volumeCompletedProjects,
        numberOfEmployees,
        public: true,
        profilePhoto,
        showcasePhotos,
        oshaCertification,
        emr,
        affiliations: affiliations
          ? affiliations
              .filter(Boolean)
              .map((affiliation) =>
                typeof affiliation === "string" ? affiliation : affiliation.name
              )
          : [],
        provider: {
          isContractor,
          workForOtherContractors,
          licensed,
          utilizeUnionLabor,
          paymentProcessTime,
          isVendor,
          licenseNumber,
        },
      },
    }
  },
  methods: {
    handleStepChange(step) {
      this.step = step
    },
    handleTag(newTag) {
      this.fields.affiliations = this.fields.affiliations.concat(newTag)
    },
    handlePhotoChange(
      profilePhotoUpload,
      showcasePhotosUpload,
      profilePhoto,
      showcasePhotos
    ) {
      this.profilePhotoUpload = profilePhotoUpload
      this.showcasePhotosUpload = showcasePhotosUpload
      this.profilePhoto = profilePhoto
      this.showcasePhotos = showcasePhotos
    },
    handleNextStep(proceedNext) {
      const step = this.step
      const normalized = normalize(this.fields)
      const isProvider = this.profileType === "provider"
      const data = {
        ...normalized,
        ...(isProvider
          ? {
              provider: {
                ...normalized.provider,
              },
            }
          : {}),
      }
      if (proceedNext) {
        localStorage.setItem("profileFields", JSON.stringify(data))
        if (this.step === this.totalSteps) {
          void this.submitProfile(data)
        } else {
          localStorage.setItem("profileStep", step + 1)
          this.step++
        }
      }
    },
    async validate() {
      const step = this.step

      if (step === 0) {
        EventBus.$emit("validate-company-contact-info")
      } else if (step === 2) {
        EventBus.$emit("validate-company-business-services")
      } else if (step === 3) {
        EventBus.$emit("validate-company-additional")
      } else if (step === 4) {
        EventBus.$emit("validate-company-photos")
      } else {
        this.errors = {}
        this.handleNextStep(true)
      }
    },
    async submitProfile(validatedData) {
      this.loading = true
      const { userId } = this.$store.state.user ?? {}

      //const isVendor = this.profileType === "vendor"
      const isProvider = this.profileType === "provider"

      const { provider, ...data } = validatedData
      if (isProvider) {
        if (!provider.isContractor) {
          provider.workForOtherContractors = false
        }
      }
      try {
        const {
          profilePhoto: uploadedProfilePhoto,
          showcasePhotos: uploadedShowcasePhotos,
        } = await uploadProfilePhotos(
          this.profilePhotoUpload,
          this.showcasePhotosUpload,
          this
        )

        const newShowcasePhotos = this.showcasePhotos
          ? [...this.showcasePhotos]
          : []
        if (uploadedShowcasePhotos.length > 0) {
          newShowcasePhotos.push(...uploadedShowcasePhotos)
        }

        const profilePhoto = uploadedProfilePhoto ?? this.profilePhoto
        const showcasePhotos = newShowcasePhotos

        if (this.profile?.profilePhoto && !this.profilePhoto) {
          await file.removeFile({
            id: this.profile.profilePhoto.id,
            type: "profilePhoto",
          })
        }

        if (this.profile?.showcasePhotos.length > this.showcasePhotos.length) {
          const showCasePhotoIds = this.showcasePhotos.map((photo) => photo.id)
          this.profile?.showcasePhotos.forEach(async (photo) => {
            if (!showCasePhotoIds.includes(photo.id)) {
              await file.removeFile({
                id: photo.id,
                type: "showcasePhoto",
              })
            }
          })
        }

        const profileData = {
          ...data,
          ...(isProvider ? { provider } : {}),
          user: userId,
          profilePhoto,
          showcasePhotos,
        }

        const editMode = this.editMode

        const payload = editMode
          ? { profileId: this.profile._id, data: profileData }
          : profileData
        const response = await this.$store.dispatch(
          `api/${
            editMode
              ? profileData.type == "jobSeeker"
                ? "updateProfile"
                : "updateCompanyProfile"
              : profileData.type == "jobSeeker"
              ? "createProfile"
              : "createCompanyProfile"
          }`,
          payload
        )
        if (!response.data) {
          this.showErrorMessage(
            response?.data?.errors?.[0],
            `We couldn't ${editMode ? "update" : "create"} your profile.`
          )
        } else if (response.data?._id) {
          if (response.data.errors) {
            this.showErrorMessage(
              response?.data?.errors?.[0],
              `Profile ${
                editMode ? "updated" : "created"
              }, but with errors. Please check your profile to make sure it is complete.`
            )
            sleep(3000)
          }
          this.$store.commit("UPDATE_USER_PROFILE", response.data)
          localStorage.removeItem("profileFields")
          localStorage.removeItem("profileStep")
          await this.$store.dispatch("auth/refreshToken")
          if (editMode) {
            this.$emit("close-modal")
            this.$emit("update-complete")
          } else {
            this.$router.push("/dashboard")
          }
        } else {
          this.loading = false
          // Default case
          this.showErrorMessage(
            response?.data?.errors?.[0],
            `We couldn't ${editMode ? "update" : "create"} your profile.`
          )
        }
      } catch (error) {
        this.loading = false
        if (error?.errors?.[0] === API_RESPONSE_ERRORS.INVALID_ZIP_CODE_ERROR) {
          this.showErrorMessage(
            error?.errors?.[0],
            ERROR_MESSAGES.INVALID_ZIP_CODE_INPUT
          )
        } else {
          this.showErrorMessage(
            error,
            error.message ??
              `We couldn't ${this.editMode ? "update" : "create"} your profile.`
          )
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" global>
.form-group legend {
  color: var(--Gray-900, #343946);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
</style>

<style lang="scss" scoped>
.empty {
  box-shadow: none;
  padding: 0;
  height: 1.375rem;
}

.dotted {
  border: 2px dashed #f77f00;
  color: #f77f00;
  display: inline;
  background-color: transparent;
}
</style>
